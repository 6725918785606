import { Currency, Lang } from '@/i18n/types'

export const locales: Lang[] = [
  'bs',
  'en',
  // 'tr',
  // 'hr',
  // 'sr'
]
export const defaultLocale: Lang = 'bs'

export const otherLocales: Lang[] = locales.filter(l => l !== defaultLocale)

export const currencies: Currency[] = ['BAM', 'EUR', 'USD']
export const defaultCurrency: Currency = 'BAM'

export const i18nConfig = {
  locales,
  defaultLocale,
  prefixDefault: true,
}

export { default as initTranslations } from './init-translations'
export { default as TranslationsProvider } from './translation-provider'

export * from './helper'
export * from './types'
