import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src536767841/src/esimify/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/src/components/layout/footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src536767841/src/esimify/src/components/layout/navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TawkTo"] */ "/codebuild/output/src536767841/src/esimify/src/components/tawk-to/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src536767841/src/esimify/src/i18n/translation-provider.tsx");
